import axios from 'axios';

import config from 'config';

export const putAccessLog = async (apiKey: string, data: any) => {
  if (config.apiUrl.startsWith('http://localhost')) {
    return;
  }

  try {
    await axios.put(
      `${config.apiUrl}/accesslog`,
      {
        data: data,
      },
      {
        headers: { 'x-api-key': apiKey },
        timeout: 30000, // 30 secs
      }
    );
  } catch (error) {
    try {
      await axios.put(
        `${config.apiUrl}/accesslog`,
        {
          data: data,
        },
        {
          headers: { 'x-api-key': apiKey },
          timeout: 30000, // 30 secs
        }
      );
    } catch (error) {
      console.log(error);
    }
  }
};
