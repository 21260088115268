import dynamic from 'next/dynamic';

export const PacificTheme = {
  PMPKeyVisualView: dynamic(() => import('./PMPKeyVisualView/PMPKeyVisualView')),
  PMPProductCardView: dynamic(() => import('./PMPProductCardView/PMPProductCardView')),
  ProductDetailsView: dynamic(() => import('./ProductDetailsView/ProductDetailsView')),
  ProductListView: dynamic(() => import('./ProductListView/ProductListView')),
  ProductSummaryCardView: dynamic(() => import('./ProductSummaryCardView/ProductSummaryCardView')),
  ProductContentsSectionHeader: dynamic(
    () => import('./ProductContentsSectionHeader/ProductContentsSectionHeader')
  ),
  BookingWidgetSectionHeader: dynamic(
    () => import('./BookingWidgetSectionHeader/BookingWidgetSectionHeader')
  ),
  TopPageAdditionalImageCard: dynamic(
    () => import('./TopPageAdditionalImageCard/TopPageAdditionalImageCard')
  ),
};
