import * as React from 'react';

interface LayoutContextInterface {
  showHeaderFooter: boolean;
  setShowHeaderFooter: ((value: boolean) => void) | null;
}

export const LayoutContext = React.createContext<LayoutContextInterface>({
  setShowHeaderFooter: null,
  showHeaderFooter: true,
});
