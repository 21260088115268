// Selectors

import { createSelector } from 'reselect';

import { ReduxState } from 'ducks';

export const selectBookingWidgetHeaderDescription = createSelector(
  (state: ReduxState) =>
    state.server.settings.all.booking_widget_design_params?.header_description_text,
  (headerText) => headerText || ''
);

export const selectBookingWidgetBannerImageUrls = createSelector(
  (state: ReduxState) => state.server.settings.all.booking_widget_design_params?.banner_image_url,
  (state: ReduxState) =>
    state.server.settings.all.booking_widget_design_params?.additional_banner_image_urls,
  (bannerImageUrl, additionalBannerImageUrls) =>
    [bannerImageUrl || '', ...(additionalBannerImageUrls ?? [])].filter((url) => Boolean(url))
);

export const selectBookingWidgetBannerTitle = createSelector(
  (state: ReduxState) => state.server.settings.all.booking_widget_design_params?.banner_title,
  (bannerTitle) => bannerTitle || ''
);

export const selectBookingWidgetBannerDescription = createSelector(
  (state: ReduxState) => state.server.settings.all.booking_widget_design_params?.banner_description,
  (bannerDescription) => bannerDescription || ''
);

export const selectBookingWidgetPrimaryColor = createSelector(
  (state: ReduxState) => state.server.settings.all.booking_widget_design_params?.primary_color,
  (primaryColor) => primaryColor || ''
);

export const selectBookingWidgetSecondaryColor = createSelector(
  (state: ReduxState) => state.server.settings.all.booking_widget_design_params?.secondary_color,
  (secondaryColor) => secondaryColor || ''
);

export const selectBookingWidgetBackgroundColor = createSelector(
  (state: ReduxState) => state.server.settings.all.booking_widget_design_params?.background_color,
  (backgroundColor) => backgroundColor || ''
);

export const selectBookingWidgetSubheaderColor = createSelector(
  (state: ReduxState) => state.server.settings.all.booking_widget_design_params?.key_texts_color,
  (subheaderColor) => subheaderColor || ''
);

export const selectBookingWidgetHeaderBackgroundColor = createSelector(
  (state: ReduxState) =>
    state.server.settings.all.booking_widget_design_params?.header_background_color,
  (headerBackgroundColor) => headerBackgroundColor || ''
);

export const selectBookingWidgetFooterBackgroundColor = createSelector(
  (state: ReduxState) =>
    state.server.settings.all.booking_widget_design_params?.footer_background_color,
  (footerBackgroundColor) => footerBackgroundColor || ''
);

export const selectBookingWidgetContentSectionBackgroundColor = createSelector(
  (state: ReduxState) =>
    state.server.settings.all.booking_widget_design_params?.content_section_background_color,
  (contentSectionBackgroundColor) => contentSectionBackgroundColor || ''
);

export const selectBookingWidgetTheme = createSelector(
  (state: ReduxState) => state.server.settings.all.booking_widget_design_params?.theme,
  (theme) => theme || ''
);
