import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import * as React from 'react';
import NProgress from 'nprogress';
import { useSelector } from 'react-redux';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { ReduxState } from 'ducks';
import { useThemeParams } from 'hooks/useThemeParams';
import { selectIsPMP } from 'lib/util/privateMarketplace';
import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { getUserId } from 'lib/util/getUserId';
import { LayoutContext } from 'contexts/LayoutContext';
import { putAccessLog } from 'lib/util/putAccessLog';

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
    gray: Palette['primary'];
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary'];
    gray: Palette['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true;
    gray: true;
  }
}

type Props = {
  children: React.ReactNode;
};

export const Layout = ({ children }: Props): any => {
  const [showHeaderFooter, setShowHeaderFooter] = React.useState<boolean>(true);
  const themeParams = useThemeParams();
  const router = useRouter();
  const { apiKey } = React.useContext(ApiKeyContext);
  const isPMP = useSelector(selectIsPMP);

  let theme = responsiveFontSizes(
    createTheme({
      palette: {
        primary: {
          main: themeParams.primaryColor,
        },
        secondary: {
          main: themeParams.secondaryColor,
        },
      } as any,
    })
  );

  theme = createTheme(theme, {
    palette: {
      black: theme.palette.augmentColor({
        color: {
          main: '#000000',
        },
        name: 'black',
      }),
      gray: theme.palette.augmentColor({
        color: {
          main: '#e0e0e0',
        },
        name: 'gray',
      }),
    },
  });

  React.useEffect(() => {
    if (themeParams.primaryColor) {
      // This is kind of a hack: the nprogress bar is mounted outside the main tree so it doesn't get the theme colors. Instead, we set them directly in this
      // custom template.
      NProgress.configure({
        template: `<div style="background: ${themeParams.primaryColor}" class="bar" role="bar"><div style="box-shadow: 0 0 10px ${themeParams.primaryColor}, 0 0 5px ${themeParams.primaryColor};" class="peg"></div></div>`,
      });
    }
  }, [themeParams.primaryColor]);

  let path = router.asPath.replace(`/${apiKey}`, '');
  if (isPMP && router.pathname === '/top') {
    path = '/top';
  }

  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const canonicalDomain = settings?.canonical_domain;
  const canonicalUrl = canonicalDomain ? `https://${canonicalDomain}${path}` : '';
  const iconUrl = settings.icon_url || '';

  React.useEffect(() => {
    const userId = getUserId();
    putAccessLog(apiKey, {
      user_id: userId,
      supplier_id: settings.supplier_id,
      path: path,
      action: 'ACCESS',
      utime: Date.now(),
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Head>
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {iconUrl && <link rel="shortcut icon" href={iconUrl} />}
        {iconUrl && <link rel="apple-touch-icon" href={iconUrl} />}
      </Head>
      <LayoutContext.Provider
        value={{
          setShowHeaderFooter,
          showHeaderFooter,
        }}
      >
        <BookingWidgetThemeContext.Provider value={themeParams.themeName}>
          <div className="theme-wrapper">
            {children}
            <style jsx global>{`
              .theme-wrapper {
                --theme-primary-color: ${themeParams.primaryColor};
                --theme-secondary-color: ${themeParams.secondaryColor};
                --theme-background-color: ${themeParams.backgroundColor};
                --theme-subheader-color: ${themeParams.subheaderColor};
                --theme-header-background-color: ${themeParams.headerBackgroundColor};
                --theme-footer-background-color: ${themeParams.footerBackgroundColor};
                --theme-footer-text-color: ${themeParams.footerTextColor};
                --theme-content-section-background-color: ${themeParams.contentSectionBackgroundColor};
                --theme-disabled-text-color: ${themeParams.disabledTextColor};
                --theme-text-color: ${themeParams.textColor};
              }
              #nprogress {
                --theme-primary-color: ${themeParams.primaryColor};
                --theme-secondary-color: ${themeParams.secondaryColor};
              }
              body {
                background: ${themeParams.backgroundColor};
              }
            `}</style>
          </div>
        </BookingWidgetThemeContext.Provider>
      </LayoutContext.Provider>
    </ThemeProvider>
  );
};
