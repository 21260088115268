import * as React from 'react';
import { NextPage } from 'next';
import { Resource } from 'i18next';
import { I18nContext } from 'react-i18next';

declare module 'i18next' {
  interface i18n {
    initializedStoreOnce?: boolean;
    initializedLanguageOnce?: boolean;
  }
}

const getDisplayName = (Component: any) => {
  return (
    Component.displayName ||
    Component.name ||
    (typeof Component === 'string' && Component.length > 0 ? Component : 'Unknown')
  );
};

const useSSR = (initialI18nStore: Resource, initialLanguage: string) => {
  const { i18n } = React.useContext(I18nContext) || {};

  // nextjs / SSR: getting data from next.js or other ssr stack
  if (initialI18nStore && !i18n.initializedStoreOnce) {
    i18n.services.resourceStore.data = initialI18nStore;

    const initialNs =
      typeof i18n.options.ns === 'string' ? [i18n.options.ns] : i18n.options.ns || [];
    // add namespaces to the config - so a languageChange call loads all namespaces needed
    i18n.options.ns = Object.values(initialI18nStore).reduce((mem, lngResources) => {
      Object.keys(lngResources).forEach((ns) => {
        if (mem.indexOf(ns) < 0) mem.push(ns);
      });
      return mem;
    }, initialNs);

    i18n.initializedStoreOnce = true;
    i18n.isInitialized = true;
  }

  if (initialLanguage && !i18n.initializedLanguageOnce) {
    i18n.changeLanguage(initialLanguage);
    i18n.initializedLanguageOnce = true;
  }
};

export const withSSR = () => {
  return function Extend(WrappedComponent: NextPage<any>) {
    function I18nextWithSSR({
      initialI18nStore,
      initialLanguage,
      ...rest
    }: {
      initialI18nStore: Resource;
      initialLanguage: string;
    }) {
      useSSR(initialI18nStore, initialLanguage);

      return React.createElement(WrappedComponent, {
        ...rest,
      });
    }

    I18nextWithSSR.displayName = `withI18nextSSR(${getDisplayName(WrappedComponent)})`;
    I18nextWithSSR.WrappedComponent = WrappedComponent;

    return I18nextWithSSR;
  };
};
