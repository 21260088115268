import { DefaultTheme } from './Default';
import { PacificTheme } from './Pacific';
import { VibrantTheme } from './Vibrant';
import { CoolProfessionalTheme } from './CoolProfessional';
import { FamilyTheme } from './Family';

import {
  PMPKeyVisualViewProps,
  ProductListViewProps,
  ProductSummaryCardViewProps,
  ProductContentsSectionHeaderProps,
  PMPProductCardViewProps,
  ProductDetailsViewProps,
  PMPCategoryListViewProps,
  BookingWidgetSectionHeaderProps,
  TopPageAdditionalImageCardProps,
} from 'lib/themes/themes';

import pacificStyles from './Pacific/base.module.css';
import coolProfessionalStyles from './CoolProfessional/base.module.css';
import FamilyStyles from './Family/base.module.css';
import vibrantOverrides from './Vibrant/overrides.module.css';
import FamilyOverrides from './Family/overrides.module.css';

const themes = {
  DEFAULT: DefaultTheme,
  PACIFIC: PacificTheme,
  VIBRANT: VibrantTheme,
  COOL_PROFESSIONAL: CoolProfessionalTheme,
  FAMILY: FamilyTheme,
};

export type ThemeName = keyof typeof themes;

interface ComponentTheme {
  PMPKeyVisualView?: React.ComponentType<PMPKeyVisualViewProps>;
  PMPProductCardView?: React.ComponentType<PMPProductCardViewProps>;
  PMPCategoryListView?: React.ComponentType<PMPCategoryListViewProps>;
  PMPFooterView?: React.ComponentType;
  ProductListView?: React.ComponentType<ProductListViewProps>;
  ProductSummaryCardView?: React.ComponentType<ProductSummaryCardViewProps>;
  ProductContentsSectionHeader?: React.ComponentType<ProductContentsSectionHeaderProps>;
  BookingWidgetFooter?: React.ComponentType;
  BookingWidgetHeader?: React.ComponentType;
  ProductDetailsView?: React.ComponentType<ProductDetailsViewProps>;
  BookingWidgetSectionHeader?: React.ComponentType<BookingWidgetSectionHeaderProps>;
  TopPageAdditionalImageCard?: React.ComponentType<TopPageAdditionalImageCardProps>;
}

export function getCustomComponent(
  themeName: ThemeName,
  componentName: 'PMPKeyVisualView'
): React.ComponentType<PMPKeyVisualViewProps>;
export function getCustomComponent(
  themeName: ThemeName,
  componentName: 'PMPProductCardView'
): React.ComponentType<PMPProductCardViewProps>;
export function getCustomComponent(
  themeName: ThemeName,
  componentName: 'PMPFooterView'
): React.ComponentType;
export function getCustomComponent(
  themeName: ThemeName,
  componentName: 'ProductListView'
): React.ComponentType<ProductListViewProps>;
export function getCustomComponent(
  themeName: ThemeName,
  componentName: 'ProductSummaryCardView'
): React.ComponentType<ProductSummaryCardViewProps>;
export function getCustomComponent(
  themeName: ThemeName,
  componentName: 'ProductContentsSectionHeader'
): React.ComponentType<ProductContentsSectionHeaderProps>;
export function getCustomComponent(
  themeName: ThemeName,
  componentName: 'BookingWidgetFooter'
): React.ComponentType;
export function getCustomComponent(
  themeName: ThemeName,
  componentName: 'BookingWidgetHeader'
): React.ComponentType;
export function getCustomComponent(
  themeName: ThemeName,
  componentName: 'ProductDetailsView'
): React.ComponentType<ProductDetailsViewProps>;
export function getCustomComponent(
  themeName: ThemeName,
  componentName: 'PMPCategoryListView'
): React.ComponentType<PMPCategoryListViewProps>;
export function getCustomComponent(
  themeName: ThemeName,
  componentName: 'BookingWidgetSectionHeader'
): React.ComponentType<BookingWidgetSectionHeaderProps>;
export function getCustomComponent(
  themeName: ThemeName,
  componentName: 'TopPageAdditionalImageCard'
): React.ComponentType<TopPageAdditionalImageCardProps>;
export function getCustomComponent(
  themeName: ThemeName,
  componentName: keyof ComponentTheme
): ComponentTheme[keyof ComponentTheme] | undefined {
  const component = (themes[themeName] as ComponentTheme)?.[componentName];

  if (component) {
    return component;
  }

  return DefaultTheme[componentName];
}

export type ColorPalette = {
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  subheaderColor: string;
  headerBackgroundColor: string;
  footerBackgroundColor: string;
  footerTextColor: string;
  contentSectionBackgroundColor: string;
  textColor: string;
};

export function getThemeDefaultColors(themeName: ThemeName): ColorPalette {
  switch (themeName) {
    case 'VIBRANT':
      return {
        primaryColor: '#FF5A26',
        secondaryColor: '#FEBF52',
        backgroundColor: '#fffaeb',
        subheaderColor: '#651800',
        headerBackgroundColor: '#fff',
        footerBackgroundColor: '#ffefcd',
        footerTextColor: '#000',
        contentSectionBackgroundColor: '#fff5de',
        textColor: '#651800',
      };
    case 'COOL_PROFESSIONAL':
      return {
        primaryColor: '#bb0000',
        secondaryColor: '#ab5411',
        backgroundColor: '#f9f9f9',
        subheaderColor: '#000000',
        headerBackgroundColor: '#fff',
        footerBackgroundColor: '#2e1f12',
        footerTextColor: '#fff',
        contentSectionBackgroundColor: '#fff',
        textColor: '#000',
      };
    case 'FAMILY':
      return {
        primaryColor: '#FF9298',
        secondaryColor: '#C6F5CD',
        backgroundColor: '#fff',
        subheaderColor: '#50220A',
        headerBackgroundColor: '#fff',
        footerBackgroundColor: '#fff',
        footerTextColor: '#50220A',
        contentSectionBackgroundColor: '#fff',
        textColor: '#50220A',
      };
    default:
      return {
        primaryColor: '#0094cc',
        secondaryColor: '#fc0',
        backgroundColor: '#f9f9f9',
        subheaderColor: '#000000',
        headerBackgroundColor: '#fff',
        footerBackgroundColor: '#eef3f6',
        footerTextColor: '#000000',
        contentSectionBackgroundColor: '#fff',
        textColor: '#000',
      };
  }
}

export function getThemeCss(themeName: ThemeName, cssRule: string) {
  switch (themeName) {
    case 'PACIFIC':
      return pacificStyles[cssRule];
    case 'COOL_PROFESSIONAL':
      return coolProfessionalStyles[cssRule];
    case 'FAMILY':
      return FamilyStyles[cssRule];
  }

  return null;
}

export function getThemeCssOverride(themeName: ThemeName, cssRule: string) {
  switch (themeName) {
    case 'VIBRANT':
      return vibrantOverrides[cssRule];
    case 'FAMILY':
      return FamilyOverrides[cssRule];
  }

  return null;
}
