import dynamic from 'next/dynamic';

export const FamilyTheme = {
  PMPKeyVisualView: dynamic(() => import('./PMPKeyVisualView/PMPKeyVisualView')),
  PMPProductCardView: dynamic(() => import('./PMPProductCardView/PMPProductCardView')),
  PMPCategoryListView: dynamic(() => import('./PMPCategoryListView/PMPCategoryListView')),
  PMPFooterView: dynamic(() => import('./PMPFooterView/PMPFooterView')),
  ProductListView: dynamic(() => import('./ProductListView/ProductListView')),
  ProductSummaryCardView: dynamic(() => import('./ProductSummaryCardView/ProductSummaryCardView')),
  ProductContentsSectionHeader: dynamic(
    () => import('./ProductContentsSectionHeader/ProductContentsSectionHeader')
  ),
  BookingWidgetFooter: dynamic(() => import('./BookingWidgetFooter/BookingWidgetFooter')),
  BookingWidgetHeader: dynamic(() => import('./BookingWidgetHeader/BookingWidgetHeader')),
  ProductDetailsView: dynamic(() => import('./ProductDetailsView/ProductDetailsView')),
  BookingWidgetSectionHeader: dynamic(
    () => import('./BookingWidgetSectionHeader/BookingWidgetSectionHeader')
  ),
  TopPageAdditionalImageCard: dynamic(
    () => import('./TopPageAdditionalImageCard/TopPageAdditionalImageCard')
  ),
};
